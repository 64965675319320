<template>
  <card icon="icon" @goTo="goTo('/managerecurringdonations')" :hasEntries="countRecurringDonations" :isLoading="countRecurringDonationsStatus !== 'ready'" >
    <IconRecurring color="gold" size="size28" />
    <template v-slot:heading>
      My Recurring Donations <span v-if="countRecurringDonations">({{countRecurringDonations}})</span>
    </template>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Card: defineAsyncComponent(() => import('@/views/ProfileNavCard.vue')),
    IconRecurring: defineAsyncComponent(() => import('@/components/icons/IconRecurring.vue'))
  },
  name: 'SummaryCountCard',
  mounted () {
    this.getRecurringDonationsCount()
  },
  methods: {
    ...mapActions([
      'getRecurringDonationsCount'
    ]),
    goTo (val) {
      this.$router.push(val)
    }
  },
  computed: {
    ...mapGetters([
      'countRecurringDonations',
      'countRecurringDonationsStatus'
    ])
  }
}
</script>
